import styled from "styled-components/macro";
import { device } from "../../../styles/media";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  margin-top: 45px;
  margin-bottom: 48px;

  @media ${device.lg} {
    margin-top: 100px;
    margin-bottom: 60px;
  }
`;

export const MainTitle = styled.h1`
  font-size: ${(props) => props.theme.h1.sm.size};
  line-height: ${(props) => props.theme.h1.sm.lineHeight};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGreen};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 20px 20px 20px;

  @media ${device.md} {
    padding: 40px 0;
  }
`;

export const Title = styled.h2`
  padding-bottom: 20px;
`;

export const Content = styled.p``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Qualifications = styled.p``;

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledLi = styled.li`
  list-style: none;
  width: 100%;
`;
