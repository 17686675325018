import * as React from "react";
import { Container, Name, Text, Inner, TeamMember, Column } from "./TeamStyles";

interface Props {
  about?: string;
  team?: string;
  img?: any;
  alt?: string;
}

export default ({ about, team, img, alt }: Props) => (
  <Container>
    <Inner>
      <Column>
        <TeamMember src={img} alt={alt} />
      </Column>
      <Column>
        <Name>{team}</Name>
        <Text>{about}</Text>
      </Column>
    </Inner>
  </Container>
);
