import { gql } from "@apollo/client";

export const HOME_PAGE = gql`
  query {
    allHome {
      title
      description
      Image {
        asset {
          url
        }
      }
      imageAlt
      firstTitle
      firstDescriptionRaw
      secondTitle
      secondDescriptionRaw
      conditionsTitle
      conditions {
        item
      }
      contactTitle
      contactText
    }
  }
`;

export const CONTACT_PAGE = gql`
  query {
    allContact {
      title
      descriptionRaw
      subtitle
      address
      email
      phone
    }
  }
`;

export const TEAM_PAGE = gql`
  query {
    allTeam {
      title
      descriptionRaw
      teamMember {
        name
        aboutRaw
        mainImage {
          asset {
            url
          }
        }
        imageAlt
      }
      testimonialTitle
      testimonial {
        testimonialRaw
      }
    }
  }
`;

export const FAQ_PAGE = gql`
  query {
    allFaq {
      title
      description
      faqTitle
      faq {
        answer
        question
      }
      feesTitle
      fees {
        item
      }
      contactTitle
      contactText
    }
  }
`;
