import styled from "styled-components";
import { device } from "../../styles/media";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  padding-top: 40px;

  @media ${device.lg} {
    flex-direction: row;
    text-align: left;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.lightGreen};
`;

export const TextWrapper = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 20px auto;
  width: 100%;
  @media ${device.lg} {
    width: 90%;
  }
`;

export const HeroTitle = styled.h1`
  margin-top: 34px;
  font-weight: 400;
  font-size: ${(props) => props.theme.h1.sm.size};
  line-height: ${(props) => props.theme.h1.sm.lineHeight};
  padding-top: 30px;

  @media ${device.lg} {
    margin-top: 0px;
    font-size: ${(props) => props.theme.h1.lg.size};
    line-height: ${(props) => props.theme.h1.lg.lineHeight};
  }
`;

export const HeroText = styled.p`
  font-weight: 400;
  font-size: ${(props) => props.theme.p.sm.size};
  line-height: ${(props) => props.theme.p.sm.lineHeight};
  margin-bottom: 30px;

  @media ${device.lg} {
    font-weight: 500;
    margin-bottom: 0px;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
`;
