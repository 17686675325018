import styled from "styled-components/macro";

export const Cta = styled.div`
  outline: none;
  cursor: pointer;
  background: ${(props) => props.theme.colors.navyBlue};
  font-size: ${(props) => props.theme.h4.sm.size};
  font-weight: 300;
  color: ${(props) => props.theme.colors.white};
  padding: 16px 40px;
  margin: 0 auto;
  border-radius: 50px;
  width: max-content;
  display: flex;
  justify-content: center;
  font-weight: 400;

  &:hover {
    font-weight: bolder;
    background: ${(props) => props.theme.colors.lightGreen};
  }
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.p.sm.size};
  margin: 0;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
`;
