import * as React from "react";
import { useQuery } from "@apollo/client";
import { CONTACT_PAGE } from "../graphQl/queries";
import ContactGroup from "../components/contact/ContactGroup/ContactGroup";
import { Helmet } from "react-helmet";

interface ContactPage {
  Title?: string;
  Text?: string;
  Email?: any;
  Phone?: any;
  Address?: string;
  Contact_Information?: string;
  contact?: any;
  attributes?: any;
  data?: any;
  allContact?: any;
}

export default function Contact() {
  const { data, loading, error } = useQuery<ContactPage>(CONTACT_PAGE);

  if (loading) return <p>Loading</p>;
  if (error) return <p>Error</p>;

  return (
    <>
      <ContactGroup
        title={data?.allContact[0].title}
        text={data?.allContact[0].descriptionRaw[0].children[0].text}
        contactInfo={data?.allContact[0].subtitle}
        email={data?.allContact[0].email}
        phone={data?.allContact[0].phone}
        address={data?.allContact[0].address}
      />
      <Helmet>
        <title>The counselling practice</title>
        <meta
          name="description"
          content="couples counseling, therapist hemel hempstead, counsellor hemel hempstead, marriage counseling, EMDR, Hemel Hempstead, counselling, therapy"
        />
        <meta
          name="keywords"
          content="Counselling, Therapy, EMDR, Hemel Hempstead"
        />
        <meta
          name="twitter:title"
          content="Low fee counselling service | EMDR"
        />
      </Helmet>
    </>
  );
}
