import styled from "styled-components/macro";
import { device } from "../../../styles/media";
import TherapyCard from "../therapyCard/Therapy";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 42px;
  margin-bottom: 48px;
  @media ${device.md} {
    flex-direction: column;
  }
  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  min-height: 350px;

  @media ${device.md} {
    margin: 20px;
  }
`;
export const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  min-height: 350px;

  @media ${device.md} {
    margin: 20px;
  }
`;
export const Card = styled(TherapyCard)`
  p {
    padding: 10px 20px;
  }
`;
