import styled from "styled-components/macro";
import { device } from "../../styles/media";

export const ListCard = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-flow: row;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  padding: 0 15px;
  border-radius: 16px;
  align-items: center;
  width: 100%;
  margin: 10px;
  @media ${device.md} {
    padding: 0 20px;
  }
`;

export const ListIcon = styled.img`
  padding-right: 20px;
  width: auto;
  height: 22px;
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.p.xs.size};
  line-height: ${(props) => props.theme.p.xs.lineHeight};
  font-weight: 350;
  @media ${device.md} {
    font-size: ${(props) => props.theme.p.sm.size};
    line-height: ${(props) => props.theme.p.sm.lineHeight};
  }
`;
