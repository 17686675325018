import * as React from "react";
import ErrorPage from "../components/errorPage/ErrorPage";

export default function Error404() {
  return (
    <>
      <ErrorPage />
    </>
  );
}
