import styled from "styled-components/macro";
import { device } from "../../../styles/media";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};

  width: 100%;
  margin-top: 45px;
  margin-bottom: 48px;
  text-align: left;

  @media ${device.md} {
    flex-direction: column;
    text-align: center;
  }
  @media ${device.lg} {
    flex-direction: row;
    text-align: left;
    margin-top: 100px;
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.h1.sm.size};
  line-height: ${(props) => props.theme.h1.sm.lineHeight};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGreen};
`;

export const Content = styled.div``;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const Circle = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGreen};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 35px;
`;
export const SubTitle = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.h4.sm.size};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 20px 20px 20px;

  @media ${device.md} {
    padding: 40px 20px;
  }
`;
export const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  padding: 20px;
  min-height: 350px;

  @media ${device.md} {
    margin: 20px;
    padding: 60px 20px;
  }
`;
export const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  padding: 20px;
  min-height: 350px;

  @media ${device.md} {
    margin: 20px;
    padding: 60px 20px;
  }
`;
