import styled from "styled-components";
import { device } from "../../styles/media";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.lightGreen};
  margin-top: 120px;
  margin-bottom: 48px;
  border-radius: 16px;
`;

export const TextWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.green};
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: ${(props) => props.theme.h1.sm.size};
  line-height: ${(props) => props.theme.h1.sm.lineHeight};
  @media ${device.md} {
    font-size: ${(props) => props.theme.h1.md.size};
    line-height: ${(props) => props.theme.h1.md.lineHeight};
  }
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.p.sm.size};
  line-height: ${(props) => props.theme.p.sm.lineHeight};
  @media ${device.md} {
    font-size: ${(props) => props.theme.p.md.size};
  }
`;
