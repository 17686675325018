import styled from "styled-components/macro";
import { device } from "../../../styles/media";

export const Container = styled.div`
  /* ${(props) => props.theme.gridStyles.getContainerStyles()}; */
`;

export const Inner = styled.div`
  padding: 0 20px;
  @media ${device.md} {
    padding: 0 45px;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-weight: 350;
  margin: 0 auto;

  @media ${device.lg} {
    padding: 0 50px;
    max-width: 900px;
  }
`;
