import * as React from "react";
import { Container, Column, Title, TextWrapper } from "./ListBlockStyles";
import ListCard from "../listCard/ListCard";

interface Props {
  text?: string;
  title?: string;
  content?: string;
  item?: any;
  children?: any;
  conditions?: any;
}

export default ({
  text,
  title,
  content,
  item,
  children,
  conditions,
}: Props) => (
  <Container>
    <TextWrapper>
      <Title>{title}</Title>
    </TextWrapper>
    <Column>
      {conditions?.map((item: any, i: any) => (
        <ListCard text={item.item} key={i} />
      ))}
    </Column>
    {children}
  </Container>
);
