import React from "react";

import {
  Column,
  HeroContainer,
  HeroTitle,
  HeroText,
  HeroImage,
  TextWrapper,
} from "./HeroStyles";

interface Props {
  title?: string;
  text?: string;
  subheading?: string;
  alt?: string;
  img?: any;
}

export default ({ title, text, subheading, alt, img }: Props) => (
  <HeroContainer>
    <Column>
      <TextWrapper>
        <HeroTitle>{title}</HeroTitle>
        <HeroText>{text}</HeroText>
      </TextWrapper>
    </Column>
    <Column>
      <HeroImage src={img} alt={alt} />
    </Column>
  </HeroContainer>
);
