import styled from "styled-components/macro";
import { device } from "../../styles/media";

export const Nav = styled.div`
  position: fixed;
  z-index: 200;
  transition: transform 0.3s ease-in-out 0.2s;
  display: flex;
  z-index: 1;
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const LogoWrapper = styled.div`
  width: 80px;
  @media ${device.lg} {
    display: inline-flex;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Logo = styled.img`
  width: 70px;
  height: auto;
  margin-top: 5px;
  @media ${device.lg} {
    margin-top: 0;
  }
`;

export const NavLogo = styled.h4`
  /* line-height: 0; */
  color: ${(props) => props.theme.colors.navyBlue};
  text-transform: uppercase;
  white-space: nowrap;
  display: none;
  @media ${device.lg} {
    display: inline-flex;
    align-items: center;
  }
`;

export const MenuOpen = styled.div`
  margin-top: 5px;
`;
export const Line = styled.div`
  width: 30px;
  height: 1px;
  background: ${(props) => props.theme.colors.navyBlue};
  margin: 7px;
  @media ${device.lg} {
    display: none;
  }
`;

export const MenuClosed = styled.div`
  margin-top: 25px;
  width: 30px;
  @media ${device.lg} {
    display: none;
  }
`;

export const LineClosedLeft = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.navyBlue};
  transform: translateY(-50%) rotate(45deg);
`;

export const LineClosedRight = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.navyBlue};
  transform: translateY(-50%) rotate(-45deg);
`;

export const NavList = styled.ul<{ isOpen: any }>`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 5px;
  overflow: hidden;
  flex-direction: column;
  max-height: ${({ isOpen }) => (isOpen ? "400px" : "0")};
  transition: max-height 0.3s ease-in;
  width: 100%;

  @media ${device.lg} {
    flex-direction: row;
    max-height: ${({ isOpen }) => (isOpen ? "400px" : "100")};
    overflow: visible;
    justify-content: flex-end;
    width: 55%;
  }
`;

export const NavItem = styled.li`
  padding: 20px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  a {
    color: ${(props) => props.theme.colors.navyBlue};
    text-decoration: none;
  }
  &:after {
    display: block;
    content: "";
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
  }
  &:hover {
    transform: scaleX(1);
    transform-origin: 0 50%;
  }
`;
