import * as React from "react";
import { useQuery } from "@apollo/client";
import Hero from "../components/hero/Hero";
import { HOME_PAGE } from "../graphQl/queries";
import ListBlock from "../components/listBlock/ListBlock";
import ContactBlock from "../components/contactBlock/ContactBlock";
import TherapyBlock from "../components/therapySection/therapyBlock/TherapyBlock";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

interface HomePage {
  allHome?: any;
}

export default function Home() {
  const { data, loading, error } = useQuery<HomePage>(HOME_PAGE);

  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact");
  };

  if (loading) return <p>Loading</p>;
  if (error) return <p>Error</p>;

  return (
    <>
      <Hero
        title={data?.allHome[0].title}
        text={data?.allHome[0].description}
        img={data?.allHome[0].Image.asset.url}
        alt={data?.allHome[0].imageAlt}
      />
      <TherapyBlock
        titleOne={data?.allHome[0].firstTitle}
        textOne={data?.allHome[0].firstDescriptionRaw[0].children[0].text}
        titleTwo={data?.allHome[0].secondTitle}
        textTwo={data?.allHome[0].secondDescriptionRaw[0].children[0].text}
      />

      <ListBlock
        title={data?.allHome[0].conditionsTitle}
        conditions={data?.allHome[0].conditions}
      />
      <ContactBlock
        title={data?.allHome[0].contactTitle}
        text={data?.allHome[0].contactText}
        buttonText={"Contact Us"}
        click={navigateToContact}
      />
      <Helmet>
        <title>The counselling practice</title>
        <meta
          name="description"
          content="Confidential, Supportive Counselling and EMDR Therapy for Adults and young people"
        />
        <meta
          name="keywords"
          content="couples counseling, therapist hemel hempstead, counsellor hemel hempstead, marriage counseling, EMDR, Hemel Hempstead, counselling, therapy"
        />
        <meta
          name="twitter:title"
          content="Low fee counselling service | EMDR"
        />
      </Helmet>
    </>
  );
}
