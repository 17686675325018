import * as React from "react";
import { useQuery } from "@apollo/client";
import { FAQ_PAGE } from "../graphQl/queries";
import Faq from "../components/fees/feesBlock/FeesBlock";
import ContactBlock from "../components/contactBlock/ContactBlock";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

interface FaqPage {
  allFaq?: any;
}

export default function Fees() {
  const { loading, data, error } = useQuery<FaqPage>(FAQ_PAGE);

  const navigate = useNavigate();
  const navigateToContact = () => {
    navigate("/contact");
  };

  if (loading) return <p>Loading</p>;
  if (error) return <p>Error</p>;
  return (
    <>
      <Faq
        title={data?.allFaq[0].title}
        content={data?.allFaq[0].description}
        subTitleOne={data?.allFaq[0].feesTitle}
        feesItems={data?.allFaq[0].fees}
        subTitleTwo={data?.allFaq[0].faqTitle}
        faqItems={data?.allFaq[0].faq}
      />
      <ContactBlock
        title={data?.allFaq[0].contactTitle}
        text={data?.allFaq[0].contactText}
        buttonText={"Contact Us"}
        click={navigateToContact}
      />
      <Helmet>
        <title>The counselling practice</title>
        <meta
          name="description"
          content="Confidential, Supportive Counselling and EMDR Therapy for Adults and young people"
        />
        <meta
          name="keywords"
          content="couples counseling, Counselling, Therapy, EMDR, Hemel Hempstead"
        />
        <meta
          name="twitter:title"
          content="Low fee counselling service | EMDR"
        />
      </Helmet>
    </>
  );
}
