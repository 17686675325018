import * as React from "react";
import {
  Container,
  AddressContainer,
  Text,
  Phone,
  Email,
  ContactTitle,
  TitleWrapper,
  Inner,
} from "./ContactCardStyles";

interface Props {
  address?: string;
  phone?: any;
  email?: any;
  contactInfo?: string;
}

export default ({ address, phone, email, contactInfo }: Props) => (
  <>
    <Container>
      <Inner>
        <TitleWrapper>
          <ContactTitle>{contactInfo}</ContactTitle>
        </TitleWrapper>
        <AddressContainer>
          <Text>{address}</Text>
          <Email>{email}</Email>
          <Phone>{phone}</Phone>
        </AddressContainer>
      </Inner>
    </Container>
  </>
);
