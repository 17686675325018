import React from "react";
import {
  ContactContainer,
  ContactTitle,
  ContactText,
} from "./ContactBlockStyles";
import Cta from "../cta/Cta";

interface Props {
  text?: string;
  title?: string;
  buttonText?: string;
  click?: any;
}

export default ({ text, title, buttonText, click }: Props) => {
  return (
    <>
      <ContactContainer>
        <ContactTitle>{title}</ContactTitle>
        <ContactText>{text}</ContactText>

        <Cta text={buttonText} click={click} />
      </ContactContainer>
    </>
  );
};
