import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
} from "@apollo/client";
import { onError } from "apollo-link-error";

const cache = new InMemoryCache();
const ENDPOINT = `https://fzrvygxf.api.sanity.io/v1/graphql/production/default`;

const httpLink = new HttpLink({
  uri: ENDPOINT,
  fetch,
});

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }: any) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache,
  link: from([(errorLink as unknown) as ApolloLink, httpLink]),
});

export default client;
