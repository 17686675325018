import * as React from "react";
import { useState } from "react";
import {
  Title,
  TitleWrapper,
  Line,
  Logo,
  ButtonLeft,
  ButtonRight,
  Slider,
  SlideWrapper,
  ButtonWrapper,
  CarouselWrapper,
} from "./CarouselStyles";

import CarouselCard from "../card/CarouselCard";
import LogoMain from "../../../assets/images/logoMain.jpg";

interface Props {
  testimonials?: any;
  slides?: any;
  title?: string;
  item?: any;
}

export default ({ testimonials, slides, title }: Props) => {
  const [current, setCurrent] = useState(0);
  const length = testimonials?.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <>
      <Line />
      <TitleWrapper>
        <Logo src={LogoMain} />
        <Title>{title}</Title>
      </TitleWrapper>

      {testimonials?.length > 1 ? (
        <Slider>
          {testimonials?.map((slide: any, index: any) => {
            return (
              <>
                <SlideWrapper index={index} current={current}>
                  {index === current && (
                    <>
                      <CarouselCard
                        testimonial={slide.testimonialRaw.map(
                          (t: any, i: any) => (
                            <div key={i}>
                              {t.children.map((t: any) => t.text)}
                            </div>
                          )
                        )}
                      />
                    </>
                  )}
                </SlideWrapper>
              </>
            );
          })}
          <ButtonWrapper>
            <ButtonLeft className="buttonLeft" text={"<"} click={prevSlide} />
            <ButtonRight className="buttonRight" text={">"} click={nextSlide} />
          </ButtonWrapper>
        </Slider>
      ) : (
        testimonials?.map((item: any, i: any) => (
          <CarouselWrapper>
            <CarouselCard
              key={i}
              testimonial={item.testimonialRaw[0].children[0].text}
            />
          </CarouselWrapper>
        ))
      )}
    </>
  );
};
