import * as React from "react";
import { Container, Title, Text, Circle, Inner } from "./TherapyStyles";

interface Props {
  text?: string;
  title?: string;
  colorChange?: any;
  background?: any;
}

export default ({ text, title, colorChange, background }: Props) => (
  <Container background={background}>
    <Inner>
      <Circle colorChange={colorChange}>
        <Title>{title}</Title>
      </Circle>
      <Text>{text}</Text>
    </Inner>
  </Container>
);
