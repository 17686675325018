import * as React from "react";
import {
  Container,
  Text,
  Inner,
} from "./CarouselCardStyles";

interface Props {
  title?: string;
  testimonial?: any;
}

export default ({ title, testimonial }: Props) => (
  <>
    <Container>
      <Inner>
        <Text>{testimonial}</Text>
      </Inner>
    </Container>
  </>
);
