import styled from "styled-components/macro";


export const ContactContainer = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  margin-top: 45px;
  margin-bottom: 45px;
  padding-top: 5px;
  padding-bottom: 25px;
`;

export const ContactTitle = styled.h2`
  display: flex;
  justify-content: center;
`;

export const ContactText = styled.p`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  display: flex;
  text-align: center;
  justify-content: center;
  padding-bottom: 30px;
`;
