import styled, { css } from "styled-components";
import cta from "../../cta/Cta";
import { device } from "../../../styles/media";

export const Line = styled.div`
  border-top: 0.5px solid ${(props) => props.theme.colors.lightGreen};
  margin-bottom: 40px;
`;

export const Container = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 700px;
  padding: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.h3.sm.size};
  border-bottom: 1px solid #6fbcb6;
`;

export const Text = styled.p`
  padding: 20px 0;
`;

export const Logo = styled.img`
  width: 50px;
`;

export const Slider = styled.div`
  position: relative;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  @media ${device.md} {
    min-height: 40vh;
    margin-top: 0;
  }
  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const SlideWrapper = styled.div<{ index: any; current: any }>`
  transform: scale(1.08);
  opacity: ${(props) => (props.index === props.current ? 1 : 0)};
  transition-duration: ${(props) =>
    props.index === props.current ? "2s" : "3s ease"};
  transform: ${(props) => (props.index === props.current ? "scale(1.08)" : "")};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 50px auto;
  @media ${device.lg} {
    margin: 0;
  }
`;

export const ButtonLeft = styled(cta)`
  font-weight: bolder;
  padding: 8px 28px;
  z-index: 10;
  margin-right: 30px;
  @media ${device.lg} {
    position: absolute;
    top: 45%;
    left: 52px;
    margin-right: 0;
  }
`;

export const ButtonRight = styled(cta)`
  font-weight: bolder;
  padding: 8px 28px;
  z-index: 10;
  margin-left: 30px;
  @media ${device.lg} {
    position: absolute;
    top: 45%;
    right: 52px;
    margin-left: 0;
  }
`;

export const CarouselWrapper = styled.div`
  padding: 30px 0 50px 0;
  margin: 0 auto;
`;
