import styled from "styled-components/macro";

export const Container = styled.div``;

export const AddressContainer = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  &:after {
    content: "";
    width: 50%;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGreen};
  }
`;

export const Inner = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
`;

export const ContactTitle = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.h4.sm.size};
`;

export const Text = styled.p`
  padding: 20px 0;
`;
export const Email = styled.p`
  padding: 20px 0;
`;
export const Phone = styled.p`
  padding: 20px 0;
`;
