import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyles = createGlobalStyle`
    ${normalize}

@font-face {
  font-family: "Avenir-Regular";
  font-style: normal;
  src: local("AvenirRegular"), local("AvenirRegular"),
    url("../src/assets/fonts/Avenir-Regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Avenir-Medium";
  font-style: normal;
  src: local("AvenirMedium"), local("AvenirMedium"),
    url("../src/assets/fonts/Avenir-Medium.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Sofia-Pro";
  font-style: normal;
  src: local("SofiaPro"), local("SofiaPro"),
    url("../src/assets/fonts/Sofia-Pro-Regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Recoleta";
  font-style: normal;
  src: local("Recoleta"), local("Recoleta"),
    url("../src/assets/fonts/Recoleta.otf") format("opentype"); /* Safari, Android, iOS */
}


@font-face {
  font-family: "Helvetica";
  font-style: normal;
  src: local("Helvetica"), local("Helvetica"),
    url("../src/assets/fonts/Helvetica.ttf") format("truetype"); /* Safari, Android, iOS */
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap');

    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
    -webkit-font-smoothing: antialiased;
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: 300;
    color:#223651; 
    line-height: 26px;

    h1, h2, h3, h4 {
      font-family: 'Recoleta', 'Playfair Display', serif;
        font-weight: 100;
        letter-spacing: -.1px;
    }
  }
    

  a {
    text-decoration: none;
  }

`;

export default GlobalStyles;
