import * as React from "react";
import { Container, ColumnOne, ColumnTwo, Card } from "./TherapyBlockStyles";


interface Props {
  textOne?: string;
  textTwo?: string;
  titleOne?: string;
  titleTwo?: string;
  content?: any;
}

export default ({ titleOne, titleTwo, textOne, textTwo }: Props) => (
  <Container>
    <ColumnOne>
      <Card title={titleOne} text={textOne} colorChange />
    </ColumnOne>
    <ColumnTwo>
      <Card title={titleTwo} text={textTwo} colorChange />
    </ColumnTwo>
  </Container>
);
