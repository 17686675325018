import * as React from "react";

import {
  Container,
  Title,
  Content,
  ColumnOne,
  ColumnTwo,
  SubTitle,
  TextWrapper,
  Circle,
  ColumnWrapper,
} from "./feesBlockStyles";

import FeesCard from "../feesCard/Fees";

interface Props {
  title?: string;
  content?: string;
  faqItems?: any;
  feesItems?: any;
  subTitleOne?: string;
  subTitleTwo?: string;
  answer?: any;
  question?: any;
}

export default ({
  faqItems,
  feesItems,
  subTitleOne,
  subTitleTwo,
  title,
  content,
}: Props) => (
  <Container>
    <TextWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </TextWrapper>
    <ColumnWrapper>
      <ColumnOne>
        <Circle>
          <SubTitle>{subTitleTwo}</SubTitle>
        </Circle>
        {faqItems?.map((item: any, i: any) => (
          <React.Fragment key={i}>
            <FeesCard question={item.question} item={item.answer} />
          </React.Fragment>
        ))}
      </ColumnOne>
      <ColumnTwo>
        <Circle>
          <SubTitle>{subTitleOne}</SubTitle>
        </Circle>
        {feesItems?.map((item: any, i: any) => (
          <FeesCard item={item.item} key={i} />
        ))}
      </ColumnTwo>
    </ColumnWrapper>
  </Container>
);
