import * as React from "react";
import { Container, Terms, TermsContainer, Question } from "./FeesStyles";

interface Props {
  item?: any;
  question?: any;
}

export default ({ item, question }: Props) => (
  <>
    <Container>
      <TermsContainer>
        <Question>{question}</Question>
        <Terms>{item}</Terms>
      </TermsContainer>
    </Container>
  </>
);
