import * as React from "react";
import {
  Container,
  Column,
  TextWrapper,
  MainTitle,
  Qualifications,
} from "./TeamBlockStyles";
import TeamCard from "../teamCard/Team";

interface Props {
  title?: any;
  therapists?: any;
  description?: string;
  img?: any;
}

export default ({ therapists, description, title, img }: Props) => (
  <Container>
    <TextWrapper>
      <MainTitle>{title}</MainTitle>
      <Qualifications>{description}</Qualifications>
    </TextWrapper>
    <Column>
      {therapists?.map((item: any, i: any) => (
        <>
          <div key={i}>
            <>
              <TeamCard
                team={item.name}
                about={item.aboutRaw.map((d: any, i: any) => (
                  <div key={i}>
                    {d.children.map((t: any, i: any) => (
                      <div key={i}>{t.text}</div>
                    ))}
                  </div>
                ))}
                alt={item.imageAlt}
                img={item.mainImage.asset.url}
              />
            </>
          </div>
        </>
      ))}
    </Column>
  </Container>
);
