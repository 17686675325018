import React from "react";

import Home from "./views/Home";
import Nav from "./components/navBar/Nav";
import Therapists from "./views/Therapists";
import Faq from "./views/Faq";
import Contact from "./views/Contact";
import Error404 from "./views/404";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../src/components/footer/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="therapists" element={<Therapists />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/defaultsite" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
