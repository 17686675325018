import styled from "styled-components/macro";
import { device } from "../../styles/media";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  margin-top: 15px;
  margin-bottom: 60px;

  /* margin: 35px 0 40px 0; */
`;
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  padding-bottom: 20px;
`;

export const Content = styled.p``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 22px;
  padding: 0 20px 0 0;

  @media ${device.md} {
    padding: 0;
  }

  @media ${device.lg} {
    grid-template-columns: auto auto auto;
  }
`;

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledLi = styled.li`
  list-style: none;
  width: 100%;
`;
