import * as React from "react";
import {
  Container,
  TextWrapper,
  Title,
  ColumnOne,
  ColumnTwo,
  ColumnWrapper,
  Text,
} from "./ContactGroupStyles";
import ContactCard from "../contactCard/ContactCard";

interface Props {
  title?: string;
  text?: string;
  email?: any;
  phone?: any;
  address?: string;
  contactInfo?: string;
}

export default ({ title, text, email, phone, address, contactInfo }: Props) => (
  <Container>
    <TextWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </TextWrapper>
    <ColumnWrapper>
      <ColumnOne>
        <ContactCard
          contactInfo={contactInfo}
          address={address}
          email={email}
          phone={phone}
        />
      </ColumnOne>
      <ColumnTwo>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.5456805616136!2d-0.4766720677211505!3d51.75963073505652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48764156f0975749%3A0x55f89de3db4f93b7!2s65%20High%20St%2C%20Hemel%20Hempstead%20HP1%203AF!5e0!3m2!1sen!2suk!4v1655572135926!5m2!1sen!2suk"
          width="auto"
          height="500"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google map address display"
        ></iframe>
      </ColumnTwo>
    </ColumnWrapper>
  </Container>
);
