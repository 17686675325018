import styled from "styled-components/macro";

export const Container = styled.div<{ background: boolean }>`
  background-color: ${(props) =>
    props.background ? props.theme.colors.grey : null};
  padding: 60px 20px;
`;

export const Circle = styled.div<{ colorChange: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGreen};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Inner = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  justify-content: center;
  flex-flow: row;
  display: flex;
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.h4.sm.size};
`;

export const Text = styled.p`
  width: 100%;
  line-height: ${(props) => props.theme.p.lineHeight};
  margin-top: 35px;
`;
