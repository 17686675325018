import * as React from "react";
import { ListCard, ListIcon, Text } from "./ListCardStyles";
import check from "../../assets/images/check.png";

interface Props {
  text?: any;
}

export default ({ text }: Props) => (
  <ListCard>
    <ListIcon src={check} alt="check icon" />
    <Text>{text}</Text>
  </ListCard>
);
