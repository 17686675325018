import React from "react";

import { Container, Title, Text, TextWrapper } from "./ErrorPageStyles";
import Cta from "../cta/Cta";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <TextWrapper>
        <Title>{"Page Not Found"}</Title>
        <Text>{"Go back to the home page"}</Text>

        <Cta text="Home" click={navigateToHome} />
      </TextWrapper>
    </Container>
  );
}
