import * as React from "react";
import { Cta, Text } from "./CtaStyles";

interface Props {
  text?: string;
  click?: any;
  className?: any;
}

export default ({ text, click, className }: Props) => (
  <Cta className={className} onClick={click}>
    <Text>{text}</Text>
  </Cta>
);
