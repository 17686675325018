import React from "react";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import training from "../../assets/images/training.png";
import verified from "../../assets/images/verified.jpeg";
import bacp from "../../assets/images/bacp.png";

// import { TweenMax, Power1 } from 'gsap'

import {
  Column,
  Footer,
  FooterTitle,
  FooterText,
  FooterIcon,
  FooterImage,
  FooterImageTraining,
  IconWrapper,
  CopyRight,
  Container,
} from "./FooterStyles";

interface Props {
  title?: string;
  text?: string;
  subheading?: string;
  alt?: string;
  img?: any;
}

export default ({ title, text, subheading, alt, img }: Props) => (
  <Footer>
    <Container>
      <Column>
        <FooterTitle>Contact Us</FooterTitle>
        <a href="mailto:roxanne.bigwood@rbcounselling.co.uk" target="_top">
          <FooterText>info@thecounsellingpractice.co.uk</FooterText>
        </a>
      </Column>
      <Column>
        <FooterTitle>Follow Us</FooterTitle>
        <IconWrapper>
          <a
            href="https://www.facebook.com/RBCounselling.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FooterIcon src={facebook} alt={"Facebook Icon"} />
          </a>
          <a
            href="https://www.instagram.com/the_counsellingpractice/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FooterIcon src={instagram} alt={"Instagram Icon"} />
          </a>
        </IconWrapper>
      </Column>
      <Column>
        <FooterTitle>Certifications</FooterTitle>
        <a
          href="https://www.bacp.co.uk/profile/d369a27c-2e58-e811-8106-3863bb351d40/therapist?location=Watford"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Bacp"
        >
          <FooterImage src={bacp} alt={"Bcap accreditation logo"} />
        </a>
        <a
          href="https://www.psychologytoday.com/gb/counselling/roxanne-bigwood-st-albans-eng/454170"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Psychology Today"
        >
          <FooterImage src={verified} alt={"psychology today verified logo"} />
        </a>
        <FooterImageTraining
          src={training}
          alt={"Covid counselling verified logo"}
        />
      </Column>
    </Container>

    <CopyRight>© The Counselling Practice 2022.</CopyRight>
  </Footer>
);
