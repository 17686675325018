import styled from "styled-components/macro";
import { device } from "../../../styles/media";

export const Container = styled.div`
  padding: 20px 20px 30px 20px;
`;

export const TeamMember = styled.img`
  width: 190px;
  height: 190px;
  border-radius: 50%;
  margin: 0 auto 30px;
  object-fit: cover;
  @media ${device.md} {
    width: 198px;
    height: 198px;
  }

  @media ${device.lg} {
    margin: 0;
  }
`;

export const Inner = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.lg} {
    grid-template-columns: 1fr 3.5fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const Name = styled.h3`
  font-size: ${(props) => props.theme.h3.sm.size};
  margin: 0;
`;

export const Text = styled.p`
  width: 100%;
  line-height: ${(props) => props.theme.p.lineHeight};
`;
