import * as React from "react";
import { useQuery } from "@apollo/client";
import { TEAM_PAGE } from "../graphQl/queries";
import TeamBlock from "../components/team/teamBlock/TeamBlock";
import { Helmet } from "react-helmet";
import Carousel from "../components/carousel/slide/Carousel";

interface TeamPage {
  allTeam?: any;
}

export default function About() {
  const { data } = useQuery<TeamPage>(TEAM_PAGE);

  return (
    <>
      <TeamBlock
        title={data?.allTeam[0].title}
        description={data?.allTeam[0].descriptionRaw[0].children[0].text}
        therapists={data?.allTeam[0].teamMember}
      />
      <Carousel
        title={data?.allTeam[0].testimonialTitle}
        testimonials={data?.allTeam[0].testimonial}
      />
      <Helmet>
        <title>The counselling practice</title>
        <meta
          name="description"
          content="Confidential, Supportive Counselling and EMDR Therapy for Adults and young people"
        />
        <meta
          name="keywords"
          content="couples counseling, therapist hemel hempstead, counsellor hemel hempstead, marriage counseling, EMDR, Hemel Hempstead"
        />
        <meta
          name="twitter:title"
          content="Low fee counselling service | EMDR"
        />
      </Helmet>
    </>
  );
}
