import styled from "styled-components/macro";

export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
`;

export const Title = styled.h1``;

export const Content = styled.div``;

export const TermsContainer = styled.ul`
  padding-left: 8px;
`;

export const SubTitle = styled.h2``;

export const Terms = styled.li`
  list-style-type: "- ";
`;

export const Question = styled.li`
  list-style-type: " ";
  font-weight: 500;
`;
