import styled from "styled-components";
import { device } from "../../styles/media";

export const Footer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.lightGreen};
`;
export const Container = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 45px;
  padding-left: 0px;
  padding-right: 0px;
  @media ${device.md} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
`;

export const FooterTitle = styled.h2`
  font-weight: 500;
  font-size: ${(props) => props.theme.h2.sm.size};
`;

export const FooterText = styled.p`
  font-weight: 300;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterIcon = styled.img`
  margin: 0px 5px;
  width: 30px;
  height: auto;
`;

export const FooterImage = styled.img`
  width: 120px;
  height: auto;
  border-radius: 4px;
  margin-top: 16px;
`;

export const FooterImageTraining = styled.img`
  width: 85px;
  height: auto;
  border-radius: 4px;
  margin-top: 16px;
`;

export const CopyRight = styled.p`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
`;
