import React, { useState } from "react";

import {
  Nav,
  NavItem,
  NavList,
  Logo,
  NavLogo,
  MenuClosed,
  MenuOpen,
  LineClosedRight,
  LineClosedLeft,
  Line,
  LogoWrapper,
} from "./NavStyles";

import logoMain from "../../assets/images/logoMain.jpg";

import {
  BrowserRouter as Router,
  NavLink,
  useNavigate,
} from "react-router-dom";

interface Props {
  logo?: any;
  isOpen?: any;
  setIsOpen?: any;
}

export default ({ logo }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };
  const isActiveStyle = {
    textDecoration: "none",
    borderBottom: "1px solid #6FBCB6",
  };

  return (
    <Nav>
      <LogoWrapper onClick={navigateToHome}>
        <Logo src={logoMain} alt={"flower logo"} />
        <NavLogo>The counselling practice</NavLogo>
      </LogoWrapper>

      {isOpen ? (
        <MenuClosed onClick={() => setIsOpen(!isOpen)}>
          <LineClosedRight />
          <LineClosedLeft />
        </MenuClosed>
      ) : (
        <MenuOpen onClick={() => setIsOpen((prev: any) => !prev)}>
          <Line />
          <Line />
          <Line />
        </MenuOpen>
      )}

      <NavList isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <NavItem>
          <NavLink
            to="/"
            style={({ isActive }) => (isActive ? isActiveStyle : {})}
          >
            Home
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="active"
            to="/therapists"
            style={({ isActive }) => (isActive ? isActiveStyle : {})}
          >
            Our Therapists
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="active"
            to="/faqs"
            style={({ isActive }) => (isActive ? isActiveStyle : {})}
          >
            FAQ's and Fees
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className="active"
            to="/contact"
            style={({ isActive }) => (isActive ? isActiveStyle : {})}
          >
            Contact
          </NavLink>
        </NavItem>
      </NavList>
    </Nav>
  );
};
